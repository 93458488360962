<template>
    <div :class="[modalClass, {'click-down': closeClick}, {'click-up': closeClick === false}]"
         @click="close"
         @mousedown="closeDown"
         @mouseup="closeUp"
         @touchstart="closeDown"
         @touchend="closeUp"
    >
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1L13 13" stroke="black" stroke-width="1.5" stroke-linecap="round" />
            <path d="M13 1L1 13" stroke="black" stroke-width="1.5" stroke-linecap="round" />
        </svg>
    </div>
</template>
<script>
export default {
    props: {
        modalClass: {
            type: String,
            required: true
        },
        closeEvent: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            closeClick: null
        }
    },
    methods: {
        close() {
            this.$root.$emit(this.closeEvent);
        },
        closeDown() {
            this.closeClick = true;
        },
        closeUp() {
            this.closeClick = false;
            setTimeout(() => {
                this.closeClick = null;
            }, 1000);
        }
    }
}
</script>
