var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        _vm.modalClass,
        { "click-down": _vm.closeClick },
        { "click-up": _vm.closeClick === false },
      ],
      on: {
        click: _vm.close,
        mousedown: _vm.closeDown,
        mouseup: _vm.closeUp,
        touchstart: _vm.closeDown,
        touchend: _vm.closeUp,
      },
    },
    [
      _c(
        "svg",
        {
          attrs: {
            width: "14",
            height: "14",
            viewBox: "0 0 14 14",
            fill: "none",
            xmlns: "http://www.w3.org/2000/svg",
          },
        },
        [
          _c("path", {
            attrs: {
              d: "M1 1L13 13",
              stroke: "black",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
            },
          }),
          _vm._v(" "),
          _c("path", {
            attrs: {
              d: "M13 1L1 13",
              stroke: "black",
              "stroke-width": "1.5",
              "stroke-linecap": "round",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }