import Vue from 'vue';

const eventList = {
    'emit': [],
    'on': []
};

const originalEmit = Vue.prototype.$emit;
Vue.prototype.$emit = function (event, ...args) {
    if (!eventList.emit.includes(event)) {
        eventList.emit.push(event);
    }
    return originalEmit.call(this, event, ...args);
};

const originalOn = Vue.prototype.$on;
Vue.prototype.$on = function (event, callback) {
    if (!eventList.on.includes(event)) {
        eventList.on.push(event);
    }
    return originalOn.call(this, event, callback);
};

Vue.prototype.$getRegisteredEvents = () => {
    return eventList.on;
};
Vue.prototype.$getEmittedEvents = () => {
    return eventList.emit;
};
